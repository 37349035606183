import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";


export default class extends Controller {

  static targets = ["form"]
  
  // connect() {
  //   console.log("connected")
  // }

  
  onPostSuccess(event) {
    // console.log("success!");
  }

  update() {
    // console.log("update");
    Rails.fire(this.formTarget, 'submit');
    // this.formTarget.innerText = "David"
  }
}

