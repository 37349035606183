import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
import "form-request-submit-polyfill"


export default class ProjectSearchController extends Controller {

  static targets = ["form", "input"]

  // connect() {
  //   console.log("connected")
  // }

  
  basicSubmit() {
    if (this.inputTarget.value.length > 1) {
      console.log("2")
      this.formTarget.requestSubmit();
    } else if (this.inputTarget.value.length === 0) {
      console.log("0")
      this.formTarget.requestSubmit();
    }
    
  }
  
  
  submit() {
    this.debounce(this.basicSubmit.bind(this))()
  }
  
  
  debounce(functionToDebounce, wait = 300) {
    let timeoutId = null
  
    return (...args) => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        timeoutId = null
        functionToDebounce(...args)
      }, wait)
    }
  }
  
  
}